<template>
  <v-container class=" my-10 pa-10" style="border-radius: 8px !important; background-color: #f2f2f2; box-shadow: 2px 2px 5px 1px rgba(0,0,0,.2) !important;">
      <v-row
        class="white ma-0 pa-0 "
        style="box-shadow: 2px 2px 5px 1px rgba(0,0,0,.2) !important; border-radius: 4px !important;"
      >
        <v-col cols="2">
          <v-text-field
            outlined
            label="Competência"
            placeholder="dd/yyyy"
            v-mask="'##/####'"
            v-model="competencia" 
            style="font-family: Roboto, sans-serif !important;"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            outlined
            label="Farol"
            v-model="farol_res"
            :items="farol" 
          ></v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            :items="colaboradores"
            outlined
            label="Colaborador"
            v-model="medico"
            return-object
            clearable

          >
          </v-autocomplete>
        </v-col>
        <v-col 
          cols="2"
        >
          <v-btn 
            class="my-1 py-6"
            outlined 
            block
            id="btn_search"
            @click="get_indicadores"
          >PESQUISAR <v-icon class="ma-2">mdi-magnify</v-icon></v-btn>
          
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <template>
            <v-treeview
              activatable
              :items="indicadores"
            ></v-treeview>
          </template>
        </v-col>
      </v-row>


  </v-container>
</template>
<script>
import api from '@/http'
export default {
  name:"Avaliador",
  data:()=>({
    competencia:['09/2021','10/2021','11/2021'],
    colaboradores:[],
    farol_res:'',
    medico:'',
    farol:[],
    indicadores:[]

  }),
  methods:{
    get_farol(){
      return new Promise(()=>{
        api({
          url: "/farol/lista/",
          method: 'get',
        }).then(res=>{

          console.log(res.data)
          this.farol = res.data
        })
      })
      
    },
    get_colaborador(){
      return new Promise(()=>{
        api({
          url: "/etl/get-prestador/",
          method: 'get',
        }).then(res=>{
          this.colaboradores = res.data
        })
      })
    },
    get_indicadores(){
      return new Promise(()=>{
        api({
          url: "/farol/performance-grupo/?competencia=10/2021&farol=1&cod=",
          method: 'get',
        }).then(res=>{
          this.indicadores = res.data
          console.log(this.indicadores)
        })
      })
    }
  },
  mounted(){
    this.get_indicadores()
    this.get_farol()
    this.get_colaborador()
    console.log([this.medico,this.farol_res,this.competencia])
  }
}
</script>